<template>
  <div class="text-center">
    <img src="/images/upgrade.svg" width="300" alt="" />
    <div class="mt-4">
      <h4>{{ getActivePlan.status ? title : $t("noPackageTitle") }}</h4>
      <p class="mt-4">
        {{ getActivePlan.status ? description : $t("noPackageDescription") }}
      </p>
      <div class="mt-4">
        <router-link to="/packages">
          <button class="btn btn-pill donate">
            <i class="icon-features"></i> {{ $t("upgradeAccount.button") }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Modules from "../../mixins/Modules";
export default {
  mixins: [Modules],
  props: {
    title: {
      default: function () {
        return this.$t("upgradeAccount.title");
      },
    },
    description: {
      default: function () {
        return this.$t("upgradeAccount.description");
      },
    },
  },
};
</script>

<style></style>
